import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import { fromLonLat } from "ol/proj.js";
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {Circle, Fill, Stroke, RegularShape, Text, Style, Icon} from 'ol/style.js';
//import GeoJSON from 'ol/format/GeoJSON.js';

import {apiGetDiveSites, apiGetCityPorts} from '../conf.js';

import site_list from '../data/sites.json';
import port_list from '../data/ports.json';

// =================================== Colors definition for highlight and select mode =================================

// color for HIGHLIGHT
const HIGHLIGHT_COLOR_STROKE = '#FF0000';
// color for SELECTION
const SELECT_COLOR_STROKE    = HIGHLIGHT_COLOR_STROKE;
const SELECT_COLOR_FILL      = '#FFA0A0';

// ================================================= Styles definition =================================================

// ----------------------------------------------- LOCATION-SELECTED object --------------------------------------------

function locationselected_create_style(name) {
     return new Style({
                image: new RegularShape({
                          fill: new Fill({color: '#FF0000'}),
                          stroke: new Stroke({color: '#880015', width: 2}),
/*
                          points: 4,
                          radius: 8,
                          angle: Math.PI / 4,
                          rotation: Math.PI / 4,

                          radius: 10 / Math.SQRT2,
                          radius2: 10,
                          points: 4,
                          angle: 0,
                          scale: [1, 0.5], */

                          points: 5,
                          radius: 10,
                          radius2: 4,
                          angle: 0,
                        }),
                text: new Text({
                          text: name, // 'Selection'
                          //textAlign: 'Center',
                          font: 'Bold 14px/1 Arial',
                          fill: new Fill({color: '#cc0000'}), // aa3300
                          stroke: new Stroke({color: '#ffffff', width: 4}),
                          offsetY: -20,
                        }),
     });
}

// ------------------------------------------------- CIRCLE AREA object ------------------------------------------------

function area_create_style() {
     return new Style({
                    stroke: new Stroke({color: '#FF0000', width: 1}),
                    fill: new Fill({color: 'rgba(255,0,0,0.05)' }),
                    //zIndex: -1,
                   });

}

// ----------------------------------------------------- PORT object ---------------------------------------------------

function port_create_style_for_mode(mode) {
    var [stroke_color,fill_color] = getcolor_for_mode(mode,'#FFFFFF', '#404040');
    return new Style({
           image: /* new RegularShape({
                      fill: new Fill({color: fill_color}),
                      stroke: new Stroke({color: stroke_color, width: 2}),
                      points: 4,
                      radius: 5,
                      angle: Math.PI / 4,
                      rotation: Math.PI / 4,
                    }), */
                    new Icon({
                      anchor: [0.5, 30], //46],
                      anchorXUnits: 'fraction',
                      anchorYUnits: 'pixels',
                      src: 'images/outline_anchor_black_24dp.png',
                    }),  
                    
            text: new Text({
                      text: '', //'\uf13d',
                      //font: '900 14px "Font Awesome 5 Free"',                      
                      font: '900 14px "Arial"',   
                      stroke: new Stroke({color: '#ffffff', width: 2}),
                      //offsetY: -14,
                      textBaseline: 'top'
                    }), 
    })
}

// ------------------------------------------------- DIVING SITES object -----------------------------------------------

// return [stroke_color, fill_color]
function getcolor_for_depth(depth) {
    switch (depth) {
        case DEPTH_LOW:
            //return ['#DDDDDD','#667FEC'];
			return ['#DDDDDD','#008000'];
        case DEPTH_MEDIUM:
            return ['#DDDDDD','#0000FF'];
        case DEPTH_HIGH:
            //return ['#EEEEEE','#000080'];
			return ['#EEEEEE','#800000'];
    }
}

function site_create_style_for_mode_and_depth(mode, depth) {
    // get colors for depth then for mode
    var [stroke_color,fill_color] = getcolor_for_depth(depth);
    [stroke_color,fill_color]     = getcolor_for_mode(mode, stroke_color, fill_color);

    return new Style({
           image: new Circle({
                      fill: new Fill({color: fill_color}),
                      stroke: new Stroke({color: stroke_color, width: 2}), // width: 2}),
                      radius: 7, //5,
                    }),
          text: new Text({
            text: 'test',             
            font: '900 11px "Arial"',
            stroke: new Stroke({color: '#ffffff', width: 1}),
            offsetY: 7,
            textBaseline: 'top'
          }),                     
    })
}

// ------------------------------------------------- WRECK SITES object -----------------------------------------------

function sitewreck_create_style_for_mode_and_depth(mode, depth) {
    // get colors for depth then for mode
    var [stroke_color,fill_color] = getcolor_for_depth(depth);
    [stroke_color,fill_color]     = getcolor_for_mode(mode, stroke_color, fill_color);

    return new Style({
           image: new RegularShape({
                      fill: new Fill({color: fill_color}),
                      stroke: new Stroke({color: stroke_color, width: 2}),
                      points: 3,
                      radius: 10, //7,
                      angle: 0,
                      rotation: Math.PI / 4,
                    }),
           text: new Text({
                    text: 'test',             
                    font: '900 11px "Arial"',
                    stroke: new Stroke({color: '#ffffff', width: 1}),
                    offsetY: 7,
                    textBaseline: 'top'
          }),                    
    })
}

// ============================================== MANAGEMENT FUNCTIONS =================================================


// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
function stringDivider(str, width, spaceReplacer) {
  if (str.length > width) {
    let p = width;
    while (p > 0 && str[p] != ' ' && str[p] != '-') {
      p--;
    }
    if (p > 0) {
      let left;
      if (str.substring(p, p + 1) == '-') {
        left = str.substring(0, p + 1);
      } else {
        left = str.substring(0, p);
      }
      const right = str.substring(p + 1);
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
}


// ---------------------------------------- Colors for highlight and select mode ---------------------------------------

// return colors for mode
function getcolor_for_mode(mode, default_stroke_color, default_fill_color) {
    switch (mode) {
        case MODE_HIGHLIGHT:
            return [HIGHLIGHT_COLOR_STROKE, default_fill_color];            
        case MODE_SELECT:
            return [SELECT_COLOR_STROKE, SELECT_COLOR_FILL];            
        default:
            return [default_stroke_color, default_fill_color];
    }
}

// return style name depnding site depth and type (wreck or not)
function get_style_from_depth(depth, is_wreck) {
    let style_name = '';
    if (is_wreck) {
       style_name = 'wreck_';
     } else {
       style_name = 'site_';
     }
     switch (true) {
       case (depth >= DEPTH_LOW_MAX):
          return style_name + 'deep_low';
       case (depth >= DEPTH_MEDIUM_MAX):
          return style_name + 'deep_medium';
        default:
          return style_name + 'deep_high';
     }
}

// ---------------------------------------------- Port styles duplication ----------------------------------------------

function port_create_all_styles(styles_dict, key) {
    styles_dict[key]                = port_create_style_for_mode(MODE_NORMAL);
    styles_dict[key + '_highlight'] = port_create_style_for_mode(MODE_HIGHLIGHT);
    styles_dict[key + '_select']    = port_create_style_for_mode(MODE_SELECT);

    styles_dict[key + '_highlight'].setZIndex(1);
    styles_dict[key + '_select'].setZIndex(1);
}

// ---------------------------------------------- Site styles duplication ----------------------------------------------

function site_create_mode_styles(styles_dict, key, depth, create_style_function) {
    styles_dict[key]                = create_style_function(MODE_NORMAL,    depth);
    styles_dict[key + '_highlight'] = create_style_function(MODE_HIGHLIGHT, depth);
    styles_dict[key + '_select']    = create_style_function(MODE_SELECT,    depth);

    styles_dict[key + '_highlight'].setZIndex(1);
    styles_dict[key + '_select'].setZIndex(1);
}

function site_create_all_styles(styles_dict, key, create_style_function) {
    site_create_mode_styles(styles_dict, key+'_low',    DEPTH_LOW, create_style_function);
    site_create_mode_styles(styles_dict, key+'_medium', DEPTH_MEDIUM, create_style_function);
    site_create_mode_styles(styles_dict, key+'_high',   DEPTH_HIGH, create_style_function);
}

// ===================================================== M A I N =======================================================

// ---------------------------------------------------- Constants ------------------------------------------------------

const MODE_NORMAL    = 1
const MODE_HIGHLIGHT = 2
const MODE_SELECT    = 3

const DEPTH_LOW      = 1
const DEPTH_MEDIUM   = 2
const DEPTH_HIGH     = 3

const DEPTH_LOW_MAX    = -20
const DEPTH_MEDIUM_MAX = -40
//const DEPTH_HIGH     = 3

// -------------------------------------------------- Full table -------------------------------------------------------

function init_styles_dict(locationselected_name) {
    var styles_dict = {};

    styles_dict['port_selected'] = locationselected_create_style(locationselected_name);
    styles_dict['area']          = area_create_style();

    port_create_all_styles(styles_dict, 'port');
    site_create_all_styles(styles_dict, 'site_deep',  site_create_style_for_mode_and_depth);
    site_create_all_styles(styles_dict, 'wreck_deep', sitewreck_create_style_for_mode_and_depth);

    return styles_dict;
}

function init_port_styles_dict(locationselected_name) {
  var styles_dict = {};

  styles_dict['port_selected'] = locationselected_create_style(locationselected_name); 
  port_create_all_styles(styles_dict, 'port');

  return styles_dict;
}


export function create_site_feature_layer(renderFeature) {
    const styles_dict = init_styles_dict('test');
    //console.log(styles_dict);

    // --- create table of sites ---    
    /*
    const nb_site = site_list.length;
    let tab_features = new Array(nb_site);
    // all sites
    for (let i = 0; i < site_list.length; i++) {
      tab_features[i] = new Feature({
        geometry: new Point(fromLonLat([site_list[i].lon, site_list[i].lat])),
        i: i,
        id: site_list[i].id,
        name: site_list[i].name,
        floor_depth: site_list[i].floor_depth,
        is_wreck: site_list[i].is_wreck,
        custom_style: get_style_from_depth(
          site_list[i].floor_depth,
          site_list[i].is_wreck
        ),
      });
    } */

     // - define site layers with features table
     const sites_layer = new VectorLayer({
        title: 'Sites',
        ShowDiveSiteType: 'ALL SITES',
        ShowDiveSiteDepthUp: '',
        ShowDiveSiteDepthDown: '',
        highlightedFeature: '',
        selectedFeature:'',
        zIndex: 1000,
        opacity: 0.2,
        source: new VectorSource(),  // {features: tab_features}
        style: function (feature, resolution) {
            // filter type of diving site
            if (sites_layer.get("ShowDiveSiteType") === "") return;
            if ((sites_layer.get("ShowDiveSiteType") === "EXPLO") && (feature.get('is_wreck'))) return;
            if ((sites_layer.get("ShowDiveSiteType") === "WRECKS") && (!feature.get('is_wreck'))) return;

            // filter depth of diving site
            const site_depth = feature.get('floor_depth');
            const depth_up = sites_layer.get("ShowDiveSiteDepthUp");
            const depth_down = sites_layer.get("ShowDiveSiteDepthDown");            
            if ((depth_up || depth_down) && ((site_depth >= depth_up) || (site_depth < depth_down))) return;            

            // define style name
            var style_name = "";
            if (feature.get("id") === sites_layer.get("selectedFeature")) 
              style_name = feature.get('custom_style')+'_select'
            else if (feature.get("id") === sites_layer.get("highlightedFeature")) 
              style_name = feature.get('custom_style')+'_highlight';              
            else 
              style_name = feature.get('custom_style');
            
            const style = styles_dict[style_name];            
            
            // show site name based on map resolution
            if (style.getText()) {
              if (resolution < 4) 
                style.getText().setText( stringDivider(feature.get('name'), 16, '\n') );
              else if (style.getText().getText() != '') 
                style.getText().setText(feature.get('')); 
            }

            return style;
           },
    }); 

    /*
    const vectorLayer = new VectorLayer({
        background: '#1a2b39',
        source: new VectorSource({
          url: '../data/sites.json',
          format: new GeoJSON(),
        }),
        style: {
          'fill-color': ['string', ['get', 'COLOR'], '#eee'],
        },
      }); */


    //const apiUrl = 'http://192.168.1.89:8000/sites/api/sites';
    loadVectorSourceFromAPI(apiGetDiveSites, sites_layer.getSource(), (i, data) => ({
      geometry: new Point(fromLonLat([data.lon, data.lat])),
      i: i,
      id: data.id,
      name: data.name,
      floor_depth: data.floor_depth,
      is_wreck: data.is_wreck,
      custom_style: get_style_from_depth(data.floor_depth, data.is_wreck),
      })
    );          
          
    return sites_layer;
}




function loadVectorSourceFromAPI(apiUrl, layerVectorSource, loadItemCallback) {
  // clear features of layerVectorSource and load them from apiURL with loadItemCallback
  layerVectorSource.clear(true);
  fetch(apiUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Process the retrieved user data
      console.log('load Ports data from api', data[0]);

      for (let i = 0; i < data.length; i++) {
        layerVectorSource.addFeature( new Feature(loadItemCallback(i, data[i]) ));
      }

     console.log('loaded Ports data from api', data.length);

    })
    .catch(error => {
      console.error('Error:', error);
    });  
}

export function create_port_feature_layer() {
  const styles_dict = init_port_styles_dict('test');  

   // - define site layers with features table
   const ports_layer = new VectorLayer({
      title: 'Ports',      
      zIndex: 1000,
      opacity: 0.9,
      source: new VectorSource(), // { features: tab_features_ports }
      style: function (feature, resolution) {     
                //console.log(resolution);
                if (resolution > 100) return;
                const style = styles_dict[feature.get('custom_style')];
                if (resolution < 10) 
                  style.getText().setText( stringDivider(feature.get('name'), 16, '\n') );
                else if (style.getText().getText() != '') 
                  style.getText().setText(feature.get(''));
                return style;
             },
  }); 

  //const apiUrl = 'http://192.168.1.89:8000/sites/api/cityports';
  //console.log(apiGetCityPorts);
  loadVectorSourceFromAPI(apiGetCityPorts, ports_layer.getSource(), (i, data) => ({
    geometry: new Point(fromLonLat([data.port_lon, data.port_lat])),
    i: i,
    id: data.id,
    name: data.port_name,
    custom_style: 'port',
    })
  );
        
  return ports_layer;
}