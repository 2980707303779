import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from "@mui/material/Fab";
//import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { fromLonLat, toLonLat } from "ol/proj.js";

import ClosePanelIcon from "@mui/icons-material/ExpandMore";

function TabPanel({ value, index }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}        
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{value}</Typography>
          </Box>
        )}
      </div>
    );
  }


function PanelFeatureInfo({ map, panelVisible, feature, onClose }) {
    const [selectedPage, setSelectedPage] = React.useState(0);

    function renderHeader() {
        const txtName = feature ? feature.get('name') : 'Undefined';
        const txtDepth = feature ? feature.get('floor_depth')+'m' : '';

        return (
        <Paper
            square
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                padding: "10px"
            }}
        >
            <Box sx={{ marginLeft: "10px", marginRight: "20px", flexGrow: "1" }}>
            
                <Typography  variant="h5" align="center"  sx={{ marginBottom:"5px"}}>{txtName}</Typography>
                <center>           
                    <Button size="small" variant="outlined" sx={{ marginRight: "10px"}} onClick={() => ZoomAndCenterSite()}>Center</Button>
                    <Button size="small" variant="outlined" sx={{ marginRight: "10px"}} onClick={() => ZoomAndCenterSite(3.9)}>Zoom area</Button>
                    <Button size="small" variant="outlined" onClick={() => ZoomAndCenterSite(0.22)}>Site</Button>
                </center>
            </Box>
            
            <Fab size="small"  onClick={onClose} sx={{ width: "40px", hight:"40px" }}>
                <ClosePanelIcon />
            </Fab>

        </Paper>

        );

    }
  
    function a11yProps(index) {
        return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        };
    } 
    
    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };


    function ZoomAndCenterSite(resolution=null) {
        if (!map || !feature) return;
        const coord = feature.getGeometry().getCoordinates();  
        //const animDef = {center:fromLonLat([ toLonLat(coord) ])}; //, zoom: 1, rotation:0, duration:0};
        const animDef = resolution ? {center:coord, resolution:resolution} : {center:coord}; 
        map.getView().animate(animDef);
    }    
    
   
    function handleCenterSite() {
        //const map = getMap();
        if (!map || !feature) return;
        const coord = feature.getGeometry().getCoordinates();  
        //const animDef = {center:fromLonLat([ toLonLat(coord) ])}; //, zoom: 1, rotation:0, duration:0};
        const animDef = {center:coord}; //, zoom: 1, rotation:0, duration:10};
        map.getView().animate(animDef);
    }

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={panelVisible}
      onClose={onClose}
      //elevation={0}
    >
      {/*}
      <center><Fab
        size="small"
        onClick={() => setPanelVisibility(false)} 
        sx={{ marginTop:"10px", marginBottom:"10px" }}
    ><ClosePanelIcon /></Fab></center> */}

      <Box>
        { renderHeader() }

        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 190 }} >
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedPage}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
        >
        <Tab label="Site info" {...a11yProps(0)} />
        <Tab label="Sea state" {...a11yProps(1)} />
        <Tab label="Weather" {...a11yProps(2)} />        
      </Tabs>  

      <TabPanel value={selectedPage} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={selectedPage} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={selectedPage} index={2}>
        Item Three
      </TabPanel>

      </Box>      
      </Box>      
    </Drawer>
  );
}

export default PanelFeatureInfo;
