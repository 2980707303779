import React from "react";
import Divider from '@mui/material/Divider';

import PopupWindow from "./Popup";


function PopupWindowLayerLegend({ layerInfoTitle, layerInfoContent, layerInfoURLDesc, layerInfoURLGraphic, ...props }) {  

  return (
      <PopupWindow        
        title={layerInfoTitle}        
        {...props}
      >
        {layerInfoContent}
        <p>
          <a href={layerInfoURLDesc} target="_blank">
            Data source Link
          </a>
        </p>
        <Divider />
        <p>
          <div>
            <img src={layerInfoURLGraphic} style={{ marginRight: "10px" }}></img>
          </div>
        </p>
      </PopupWindow>
  );
}

export default PopupWindowLayerLegend;
