import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import {get as getProjection} from 'ol/proj.js';
import {getTopLeft, getWidth} from 'ol/extent.js';

const projection = getProjection('EPSG:3857');
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;

const resolutions = new Array(10);
const matrixIds = new Array(10);
for (let z = 0; z < 10; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS  
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;    
}


const URL_CMEMS_WMTS = "https://wmts.marine.copernicus.eu/teroWmts";


function createSubLayerCMEMS(title, dataId, paramId, datetime, style, maxResolution=undefined) {
  return new TileLayer({
    title: title,        
    //layerType: categoryId,
    maxResolution: maxResolution,
    //visible: false,
    //zIndex: zIndex,
    //opacity: 0.6,
    //layer_icon: "images/icon_layer_seatemp_90x60.png",
    source: new WMTS({
      url: URL_CMEMS_WMTS,
      layer: dataId + '/' + paramId,       
      matrixSet: 'EPSG:3857',
      format: 'image/png',
      dimensions: {time: datetime},      
      style: style,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds, 
      }),      
    }),         
  }); 
}

function createLayerCMEMS(title, categoryId, zIndex, layer_icon, dataId, paramId, datetime=undefined, style=undefined, 
                          legend_description, legend_url_graphic, legend_url_info) {
  return new TileLayer({
    title: title,        
    layerType: categoryId,    
    visible: false,
    zIndex: zIndex,
    //opacity: 0.6,
    layer_icon: layer_icon,

    legend_description: legend_description,
    legend_url_graphic: legend_url_graphic,
    legend_url_info: legend_url_info, 

    source: new WMTS({
      url: URL_CMEMS_WMTS,
      layer: dataId + '/' + paramId,       
      matrixSet: 'EPSG:3857',
      format: 'image/png',
      dimensions: {time: datetime},      
      style: style,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds, 
      }),      
    }),    
  }); 
}

function createGroupLayerCMEMS(
  title,
  categoryId,
  zIndex,
  layer_icon,
  layersTab,
  legend_description,
  legend_url_graphic,
  legend_url_info
) {
  // group layer
  return new LayerGroup({
    title: title,
    layerType: categoryId,
    visible: false,
    zIndex: zIndex,
    opacity: 1,
    layer_icon: layer_icon,
    layers: layersTab,

    legend_description: legend_description,
    legend_url_graphic: legend_url_graphic,
    legend_url_info: legend_url_info,
  });
}    


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupSea(categoryId="sea") {
  
    const dt_now = new Date();
    const dateTime = dt_now.toISOString(); //'2024-05-09T00:00:00Z';
    console.log(dateTime);      
   
    // ------------------------------------------------------------------------------------------------------------
    //                                          Sea Temperature layers
    // ------------------------------------------------------------------------------------------------------------

    // others local areas (mediterean, north-west atlantic)
    // "MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-tem_anfc_4.2km_P1D-m_202311/thetao"
    // "NWSHELF_ANALYSISFORECAST_PHY_004_013/cmems_mod_nws_phy_anfc_0.027deg-3D_P1D-m_202309",
  /*
    const sublayerTempCMEMS14deg = createSubLayerCMEMS(
      'sub Sea temp 14°C', 
      'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
      dateTime, "cmap:ice,range:-5/14,noClamp");

      const sublayerTempCMEMS20deg = createSubLayerCMEMS(
        'sub Sea temp 20°C', 
        'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
        dateTime, "cmap:solar,range:14/20,noClamp");

      const sublayerTempCMEMS25deg = createSubLayerCMEMS(
        'sub Sea temp 25°C', 
        'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
        dateTime, "cmap:amp,range:20/25,noClamp");        

      const layerGroupTempZoneCMEMS = createGroupLayerCMEMS(
        "Sea temperature zone", categoryId, 10, "images/icon_layer_seatemp_90x60_3.png",      
        [sublayerTempCMEMS14deg,sublayerTempCMEMS20deg,sublayerTempCMEMS25deg],

        'Sea temperature (NEMO)',
        'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
        'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
      );

    const layerTempCMEMSGlobal = createLayerCMEMS(
      "Sea temperature", categoryId, 10, "images/icon_layer_seatemp_90x60_3.png",

      "GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211",
      "thetao", dateTime, "cmap:solar,range:14/20,noClamp", // "cmap:ice,range:-5/14,noClamp",

      'Sea temperature (NEMO)',
      'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
    ); */
    
    function createLayerGroupTempCMEMS(title, style, layer_icon, legend_description) {
      const sublayerTempCMEMSGlobal = createSubLayerCMEMS(
        title + " / Sea temperature Global",      
        "GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211",      
        "thetao", dateTime, style, //"cmap:rainbow,range:-2/35", 
      );  
      
      const sublayerTempCMEMSMed = createSubLayerCMEMS(
        title + "/ Sea temperature Med",      
        "MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-tem_anfc_4.2km_P1D-m_202311",      
        "thetao", dateTime, style, //"cmap:rainbow,range:-2/35", 
      );     

      return createGroupLayerCMEMS(
        title, categoryId, 10, layer_icon, 
        [sublayerTempCMEMSGlobal,sublayerTempCMEMSMed],

        legend_description,
        'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
        'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
        );
    }
    
    const layerGroupTempCMEMS = createLayerGroupTempCMEMS('Sea Temperature', 'cmap:rainbow,range:-2/35', 'images/icon_layer_seatemp_90x60_3.png', 'Sea temperature (NEMO)');
    const layerTempCMEMS25deg = createLayerGroupTempCMEMS('Sea temp 25°C',   'cmap:rainbow,range:24.9/25', 'images/icon_layer_seatemp14deg_3.png', 'Sea temperature lower and greater 25°C (NEMO)');
    const layerTempCMEMS20deg = createLayerGroupTempCMEMS('Sea temp 20°C',   'cmap:rainbow,range:19.9/20',  'images/icon_layer_seatemp14deg_3.png','Sea temperature lower and greater 20°C (NEMO)');
    const layerTempCMEMS14deg = createLayerGroupTempCMEMS('Sea temp 14°C',   'cmap:rainbow,range:13.999/14.0',  'images/icon_layer_seatemp14deg_3.png','Sea temperature lower and greater 14°C (NEMO)');
 
   
    // ------------------------------------------------------------------------------------------------------------
    //                                          Current layers
    // ------------------------------------------------------------------------------------------------------------
    
    const layerCurrentCMEMSGlobal = createLayerCMEMS(
      "Current", categoryId, 10, "images/icon_current_2.png",

      "GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-cur_anfc_0.083deg_P1D-m_202211",
      "uo", dateTime, "cmap:rainbow", //"cmap:rainbow,range:13.999/14.0", // "cmap:rainbow,range:25/50,noClamp"

      'Current',
      'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
    ); 
   // sea_water_velocity

    
    // ------------------------------------------------------------------------------------------------------------
    //                                          Wave layers
    // ------------------------------------------------------------------------------------------------------------    
    
    const layerWaveHeightCMEMSGlobal = createLayerCMEMS(
      "Wave height", categoryId, 10, "images/icon_layer_waveheight_90x60_2.png",

      "GLOBAL_ANALYSISFORECAST_WAV_001_027/cmems_mod_glo_wav_anfc_0.083deg_PT3H-i_202311",
      //"MEDSEA_ANALYSISFORECAST_WAV_006_017/cmems_mod_med_wav_anfc_4.2km_PT1H-i_202311",
      "VHM0", dateTime, "cmap:rainbow", //"cmap:range:1.99/2.0", //"cmap:rainbow",

      'Wave height (MFWAM)',
      '',
      ''
    );  

    // ------------------------------------------------------------------------------------------------------------
    //                                          Visibility layers
    // ------------------------------------------------------------------------------------------------------------
        
    /*
    const layer_cmems_zsd = new TileLayer({
      title: "Secchi depth of sea water",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?service=WMS",
        params: { LAYERS: "ZSD", TILED: true },  
        transition: 0,
      }),
      
      legend_description: 'Depth of sea water (Secchi disk)',
      legend_url_graphic: 'https://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=ZSD&PALETTE=rainbow',
      legend_url_info: 'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description',        
    });    
    
    const layerKD490CMEMSGlobal = createLayerCMEMS(
      "Water visibility", categoryId, 10, "images/water_visi_3.png",
      
      "OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D_202311",
      //"OCEANCOLOUR_MED_BGC_L4_NRT_009_142/cmems_obs-oc_med_bgc-transp_nrt_l4-olci-300m_P1M_202207",
      "KD490", dateTime, "cmap:dense", //"cmap:rainbow", //"cmap:range:1.99/2.0", //"cmap:rainbow",

      'Diffuse attenuation coefficient',
      'http://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=KD490&PALETTE=rainbow',
      //'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L3_NRT_009_101/description',  
      'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description'
    ); 
   */

    const sublayerKD490CMEMSGlobal = createSubLayerCMEMS(
      "Water visibility global",      
      "OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D_202311",      
      "KD490", dateTime, "cmap:dense", 
    );   
    
    const sublayerKD490CMEMSMed = createSubLayerCMEMS(
      "Water visibility Med",             
      "OCEANCOLOUR_MED_BGC_L4_NRT_009_142/cmems_obs-oc_med_bgc-transp_nrt_l4-olci-300m_P1M_202207",
      "KD490", dateTime, "cmap:dense", 
    );     

    const layerGroupKD490CMEMSGlobal = createGroupLayerCMEMS(
      "Water visibility", categoryId, 10, "images/water_visi_3.png",
      [sublayerKD490CMEMSGlobal,sublayerKD490CMEMSMed],

      'Diffuse attenuation coefficient',
      'http://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=KD490&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description'
      );

  
  // ------------------------------------------------------------------------------------------------------------       

  return [    
    layerGroupKD490CMEMSGlobal,

    //layerTempCMEMSGlobal,
    //layerGroupTempZoneCMEMS,
    layerGroupTempCMEMS,
    //layerTempCMEMS25deg,
    //layerTempCMEMS20deg,
    //layerTempCMEMS14deg,

    layerWaveHeightCMEMSGlobal,     

    layerCurrentCMEMSGlobal,    
  ];
}
