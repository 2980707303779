import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";

import ButtonImage from "./ButtonImage";


function TabPageLayers({
  id,
  getmap,
  layerType,
  exclusive,
  children,
  value,
  index,
  onClickInfoLayer,
  popupLayerInfoVisible,
  onClosePopupInfoLayer,
  ...other
}) {
  // list of layers to display (as buttons)
  const [tableLayers, setTableLayers] = useState([]);
  // only used to trigger selected button refreshment (layer visibility)
  const [tableLayersVisibility, setTableLayersVisibility] = useState({});

  // toggle layer visibility
  const toggleLayerVisibility = (layer_title) => {
    const map = getmap();
    if (map == null) return;

    // find layer and update visibility
    const layer = map
      .getLayers()
      .getArray()
      .find((l) => l.get("title") === layer_title);
    if (!layer) return;      

    if (layer.get("layerType") === layerType && exclusive) {
      tableLayers.map((l) => {
        l.setVisible(l.get("title") === layer_title && !layer.getVisible());
      });
    } else {
      layer.setVisible(!layer.getVisible());
    }
    

    // update state of layer visiblity to trigger component update
    const updatedVisibility = {};
    map.getLayers().forEach((layer) => {
      updatedVisibility[layer.get("title")] = layer.getVisible();
    });
    setTableLayersVisibility(updatedVisibility);

    // update popup : hide legend popup if layer info not visible
    const layer_info = map
    .getLayers()
    .getArray()
    .find((l) => l.get("title") === popupLayerInfoVisible);
    if (layer_info && !layer_info.getVisible()) 
      onClosePopupInfoLayer();
  };

  // init layers table from map (selection on layerType) when panel is displayed
  useEffect(() => {
    if (tableLayers.length === 0) {
      const mymap = getmap();
      if (mymap) {
        const newTableLayers = mymap
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("layerType") === layerType);
        setTableLayers(newTableLayers);
      }
    }
  });

  return (
    <div
      role="tab panel"
      hidden={value !== index}
      id={ `${id}-${index}`}
      aria-labelledby={`${id}-${index}`}
      {...other}
    >
      {value === index && (
        <ImageList cols={1}>
          {" "}
          {/* sx={{ width: 115, height:330 }} cols={1} gap={10} rowHeight={72} */}
          {tableLayers.map((layer, i) => (
            <ButtonImage
              key={i * 10}
              title={layer.get("title")}
              isSelected={layer.getVisible()}
              exclusive={exclusive}
              image={String(layer.get("layer_icon"))}
              btn_width="90"
              btn_height="60"
              handleClick={toggleLayerVisibility}
              handleClickInfo={onClickInfoLayer}
              popupLayerInfoVisible={ layer.getVisible() }
              popupLayerInfoSelected={ popupLayerInfoVisible === layer.get("title") }
            />
          ))}
        </ImageList>
      )}
    </div>
  );
}

export default TabPageLayers;
