import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupWrecks(categoryId="wrecks") {
  
  const layer_shom_wrecks = new TileLayer({
    title: "Wrecks (France)",
    layerType: categoryId,
    visible: false,
    opacity: 1,
    zIndex: 100,
    layer_icon: "images/icon_layer_info_wrecks_fr_90x60.png",
    source: new TileWMS({
      url: "https://services.data.shom.fr/INSPIRE/wms/v",
      params: { LAYERS: "EPAVES_BDD_WLD_WGS84G_WMS", TILED: true, FORMAT: 'image/png' },  
      transition: 0,
    }),    
  });

  const layer_wrecks_us = new TileLayer({
    title: "Wrecks (US)",
    layerType: categoryId,
    visible: false,
    opacity: 1,
    zIndex: 100,
    layer_icon: "images/icon_layer_info_wrecks_fr_90x60.png",
    source: new TileWMS({
      url: "https://wrecks.nauticalcharts.noaa.gov/arcgis/services/public_wrecks/Wrecks_And_Obstructions/MapServer/WMSServer",
      params: { LAYERS: "5,6,7,8,9,10,11,12,13,14,15,16,17,18,19", FORMAT: 'image/png' },  
      transition: 0,
    }),    

    legend_description: 'Automated Wreck and Obstruction Information System (AWOIS) shipwreck layer, containing shipwreck features that have been compiled since OCS initiated the AWOIS database in 1981 for use in hydrographic survey planning to investigate possible navigational hazards within US coastal waters. AWOIS is presently maintained and updated, however it is not a comprehensive inventory of shipwrecks, as emphasis is continually placed on wrecks that may be a hazard to navigation, and features that have been disproved or salvaged are not included.',
    legend_url_graphic: './images/legend_wreck_us.png',
    legend_url_info: 'https://nauticalcharts.noaa.gov/data/wrecks-and-obstructions.html',    
  });  

  const layer_wrecks_ir = new TileLayer({
    title: "Wrecks (Irland)",
    layerType: categoryId,
    visible: false,
    opacity: 1,
    zIndex: 100,
    layer_icon: "images/icon_layer_info_wrecks_fr_90x60.png",
    source: new TileWMS({
      url: "https://secure.dccae.gov.ie/arcgis/services/INFOMAR/Shipwrecks/MapServer/WMSServer",
      params: { LAYERS: "Shipwrecks", FORMAT: 'image/png' },  
      transition: 0,
    }),    

    legend_description: 'INSS/INFOMAR survey shipwrecks while conducting seabed surveys using multibeam echosounders and sometimes side scan sonar. The INtegrated Mapping FOr the Sustainable Development of Irelands MArine Resource (INFOMAR) programme is a joint venture between the Geological Survey of Ireland (GSI) and the Marine Institute (MI). The programme is the successor to the Irish National Seabed Survey (INSS) and concentrates on creating integrated mapping products related to the seabed.',
    //legend_url_graphic: './images/legend_wreck_us.png',
    legend_url_info: 'https://www.infomar.ie/index.php/',    
  }); 


  return [
    layer_shom_wrecks,
    layer_wrecks_us,
    layer_wrecks_ir,      
  ];
}
