import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import ClosePanelIcon from "@mui/icons-material/ChevronRight";

import ButtonRound from "./ButtonRound";


function Panel({
  btnTop,
  btnBottom,
  btnRight,
  btnIcon,
  panelTitle = "",
  onDisplayPanel = (f) => f,
  children,
}) {
  const [panelVisible, setPanelVisibility] = useState(false);

  useEffect(() => {    
    onDisplayPanel();
  }, [panelVisible]);

  
  return (
    <>
      <ButtonRound
        label={panelTitle}
        icon={btnIcon}
        top={btnTop}
        bottom={btnBottom}
        right={btnRight}
        //zIndex={10000}
        bgColor="transparent"
        handleClick={() => setPanelVisibility(true)}
      />

      <Drawer
        anchor="right"
        variant="persistent"
        open={panelVisible}        
        onClose={() => setPanelVisibility(false)}        
        /*
        PaperProps={
          {
            //style: { margin:"100px" },
          }
        }
        // hideBackdrop={true}
        ModalProps={{
          slotProps: { backdrop: { invisible: true } },          
          //slots: { backdrop: }
        }} */
      >
       {/*
        <Typography
            variant="h7"
            align="center"
            sx={{ background:"black", color: "white", marginTop: "10px" }}
            onClick={() => setPanelVisibility(false)}
         > 
           <b>{panelTitle}</b>
      </Typography> */}

        <center><Fab
          size="small"
          onClick={() => setPanelVisibility(false)} // setPanelVisibility(false)
          sx={{ marginTop:"10px", marginBottom:"10px" }}
        ><ClosePanelIcon /></Fab></center>

        <Box sx={{ maxWidth:"120px", marginLeft: "5px", marginRight: "5px" }}>{children}</Box>

      </Drawer>

     
    </>
  );
}

export default Panel;
