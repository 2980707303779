import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import BingMaps from "ol/source/BingMaps";



// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupMaps(categoryId="background") {
  
  // ----------------------------------------------------------------------------------
  //                                 Maps background 1 : osm with coastline
  // ----------------------------------------------------------------------------------

  // OpenStreeMap (default bg)
  const layer_osm = new TileLayer({
    source: new OSM(),
    title: "Default map OSM",
    zIndex: 0,
  });

  const layer_emodnet_coasline_forgroup = new TileLayer({
    title: "Coastlines for OSM",                
    visible: true,
    zIndex: 1000,
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "coastlines", TILED: true, BGCOLOR: '0xFFFFFF' },
      transition: 0,
    }),    
  }); 

    // group layer
    const layer_group_osm = new LayerGroup({
      title: "Default map",    
      is_mapbg: true,
      layerType: categoryId,
      visible: true,
      //zIndex: 0,   
      opacity: 1,
      layer_icon: "./images/icon_layer_bg_osm_90x60.png",
      layers: [layer_osm,layer_emodnet_coasline_forgroup],
  
      legend_description: 'This is the default map background. It uses OpenStreetMap which is built by a community of mappers that contribute and maintain data about roads, trails, cafés, railway stations, and much more, all over the world.  (c) OpenStreeMap contributors.',
      legend_url_graphic: './images/legend_Openstreetmap_logo.svg.png',
      legend_url_info: 'https://wiki.openstreetmap.org/wiki/OpenStreetMap_Carto/Areas',
    });  


  // ----------------------------------------------------------------------------------
  //                                 Maps background 2 : black osm
  // ----------------------------------------------------------------------------------

  // CartoDB Dark Matter
  const layer_osm_dark = new TileLayer({
    title: "Dark map",     
    visible: false,    
    is_mapbg: true,    
    layerType: categoryId,
    //zIndex: 1000,
    //opaque: false,
    layer_icon: "./images/icon_layer_bg_osmblack_90x60.png",    
    source: new OSM({
      url: 'https://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',      
    }),    
  }); 


  // ----------------------------------------------------------------------------------
  //                                 Maps background 3 : satellite Maptiler
  // ----------------------------------------------------------------------------------  

  // Map Tiler bg
  const key = "mQEOzInrXYzlUjEyyhNf";
  const sat_maptiler_layer = new TileLayer({
    title: "Photo 1 (no label)",    
    zIndex: 0,
    visible: false,
    opacity: 1,
    is_mapbg: true,
    layerType: categoryId,
    layer_icon: "images/icon_layer_bg_maptiler_90x60.png",
    source: new XYZ({
      url:
        "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=" + key,
      maxZoom: 20,      
    }),    
  });

  // ----------------------------------------------------------------------------------
  //                                 Maps background 4 : satellite Bing
  // ----------------------------------------------------------------------------------

  // Bing bg
  const sat_bing_layer = new TileLayer({
    title: "Photo 2 (with label)",
    
    visible: false,
    zIndex: 0,
    opacity: 1,
    //preload: 0, // default value
    is_mapbg: true,
    layerType: categoryId,
    layer_icon: "images/icon_layer_bg_bing_90x60.png",
    source: new BingMaps({
      key: "AiGgreJaXWFPjBeugGU5muQ29XWrJARnEPrd8X7S67HSdSSpMfHlJ7lneFFgTH0m",
      imagerySet: "AerialWithLabelsOnDemand",
    }),    
  });

  // ----------------------------------------------------------------------------------
  //                                 Maps background 5 : bathymetry
  // ----------------------------------------------------------------------------------

  // Group of layers for bathymetry
  // layer 1 / 3
  const gebco_layer = new TileLayer({
    title: "Bathy (gebco)",    
    visible: true,    
    zIndex: 10,
    opacity: 0.6,
    //layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv",
      params: { LAYERS: "GEBCO_LATEST_2", TILED: true },
      transition: 0,
    }),    
  });
  // layer 2/3
  const bathy_emodnet_layer = new TileLayer({
    title: "Bathy emod",    
    zIndex: 11,  
    visible: true,    
    opacity: 0.6,    
    minZoom: 5,
    //layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      // params: {'LAYERS': 'emodnet:mean_atlas_land', 'TILED': true},
      params: { LAYERS: "emodnet:mean_multicolour", TILED: true },
      //serverType: 'geoserver',
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),    
  });
  // layer 3/3
  const layer_groupbathy_emodnet_coasline = new TileLayer({
    title: "Coastlines for bathy",            
    visible: true,
    zIndex: 100,
    //opacity: 0.6,    
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "coastlines", TILED: true, BGCOLOR: '0xFFFFFF' },
      transition: 0,
    }),    
  });
  const layer_group_bathy_emodnet_undersea = new TileLayer({
    title: "Undersea names for bathy",            
    visible: true,
    zIndex: 100,
    //opacity: 0.6,    
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "gebco:undersea_features", TILED: true },  // world:sea_names
      transition: 0,
    }),    
  });      
  // group layer
  const layer_group_bathy = new LayerGroup({
    title: "Bathymetry",    
    is_mapbg: true,
    layerType: categoryId,
    visible: false,
    zIndex: 0,   
    opacity: 1,
    layer_icon: "images/icon_layer_bg_bathy_90x60.png",
    layers: [gebco_layer,bathy_emodnet_layer,layer_groupbathy_emodnet_coasline,layer_group_bathy_emodnet_undersea],

    legend_description: 'This map displays sea floor mean depth and undersea features (when zooming). The World map resolution is ~450m (GEBCO_2022 Grid elevation data at 15 arc-second intervals). When zooming over Europe, a higher resolution map is displayed ~125m with colors (EMODNET map mean depth based on source resolution of 1/16 arc minute.',
    legend_url_graphic: './images/legend_EMODNET_bathy.png',
    legend_url_info: 'https://ows.emodnet-bathymetry.eu/',    
    // https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/images/gebco_depth_colour_key_vertical.jpg
    // https://ows.emodnet-bathymetry.eu/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=198&height=275&layer=emodnet%3Amean_multicolour
  });  


  // ----------------------------------------------------------------------------------
  //                                 Maps background 6 : coastline
  // ----------------------------------------------------------------------------------

  const layer_emodnet_coasline = new TileLayer({
    title: "Coastlines",        
    is_mapbg: true,
    layerType: categoryId,
    visible: false,
    zIndex: 1000,
    //opacity: 0.6,
    layer_icon: "images/icon_layer_coastline_90x60.png",
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "coastlines", TILED: true, BGCOLOR: '0xFFFFFF' },
      transition: 0,
    }),    
  });  


  return [
    //layer_osm,
    layer_group_osm,
    layer_group_bathy,
    sat_maptiler_layer,
    sat_bing_layer,
    layer_osm_dark,
    layer_emodnet_coasline,              
  ];
}
