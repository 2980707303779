import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import { toLonLat } from "ol/proj";
import Geolocation from "ol/Geolocation.js";

import MyPositionIcon from "@mui/icons-material/GpsFixed";
import CloseIcon from '@mui/icons-material/Close';

function ButtonGeoLoc({
  label,
  icon,
  getmap,
  top = "",
  right = "",
  bottom = "",
}) {
  const [openInfoMessage, setOpenInfoMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  let geolocation = null;

  const handleCloseInfoMessage = () => { //(event: React.SyntheticEvent | Event, reason?: string) => {
   /* if (reason === 'clickaway') {
      return;
    } */

    setOpenInfoMessage(false);
  };
  
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseInfoMessage}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  useEffect(() => {
    // - geolocation -
    geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true, // enableHighAccuracy must be set to true to have the heading value
      },
      projection: getmap().getView().getProjection(),
    });
    geolocation.setTracking(true);

    // handle geolocation error.
    geolocation.on("error", function (error) {
      setInfoMessage('Cannot initialize your location : '+error.message);
      setOpenInfoMessage(true);      
    });

  }, []);

  // handle geolocation button click
  const handleGotoMyLoc = () => {
    if (geolocation == null) {
        //setInfoMessage("Failed to init geoloc");
        setOpenInfoMessage(true);        
        return;
    }
    const coordinates = geolocation.getPosition();
    /*
    const info = document.getElementById(id_btn_goworld);
    if (coordinates) {
        const dispCoord = toLonLat(coordinates).map(function(val) {
        return val.toFixed(5);
    }); 
    info.innerHTML = dispCoord; */

    if (coordinates) {
      const animDef =
        getmap().getView().getZoom() > 11
          ? { center: coordinates, rotation: 0 }
          : { center: coordinates, rotation: 0, zoom: 11 };
      getmap().getView().animate(animDef);
    } else {
        setInfoMessage("Your location is undefined");
        setOpenInfoMessage(true);
    }
  };

  return (
    <>
      <IconButton
        size="medium"
        style={{ position: "absolute", top: "85px", right: "20px" }}
        onClick={() => handleGotoMyLoc()}
      >
        <MyPositionIcon />
      </IconButton>

      <Snackbar
        open={openInfoMessage}
        autoHideDuration={6000}
        onClose={handleCloseInfoMessage}
        message={infoMessage}
        action={action}
        anchorOrigin={{vertical:'bottom', horizontal:'center'}}
      />
    </>
  );
}

export default ButtonGeoLoc;
