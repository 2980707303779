import LayersIcon from "@mui/icons-material/Layers";
import SeaIcon from "@mui/icons-material/Waves";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import BathyIcon from "@mui/icons-material/Landscape";
import WrecksIcon from "@mui/icons-material/DirectionsBoat";
import InfoIcon from "@mui/icons-material/Info";

import createLayersGroupMaps from './LayersGroupMaps';
import createLayersGroupSea from './LayersGroupSea';
import createLayersGroupWeather from './LayersGroupWeather';
import createLayersGroupBathy from './LayersGroupBathy';
import createLayersGroupWrecks from './LayersGroupWrecks';
import createLayersGroupBio from './LayersGroupBio';
import createLayersGroupMisc from './LayersGroupMisc';


export const LAYERS_GROUPS = [   

    //{ label: "Areas",      icon: WorldIcon,    tabPageType: TabPageOfArea },  
    //{ label: "Dive Sites", icon: DiveSiteIcon, tabPageType: TabPageOfDiveSites },  

   // { label: "Maps",       icon: LayersIcon, tabPageType:TabPageLayers,  layerprops:{ layerType: "background", exclusive:true  } },
   // { label: "Weather",    icon: CloudIcon,  tabPageType:TabPageLayers,  layerprops:{ layerType: "weather",    exclusive:true  } },  
   // { label: "Wrecks",     icon: InfoIcon,   tabPageType:TabPageLayers,  layerprops:{ layerType: "wrecks",     exclusive:false } },  
   // { label: "Info",       icon: InfoIcon,   tabPageType:TabPageLayers,  layerprops:{ layerType: "info",       exclusive:false } },  

    { label: "Maps",       icon: LayersIcon, layerprops:{ layerType: "background",    exclusive:true  }, createFunction: createLayersGroupMaps },    
    { label: "Sea",        icon: SeaIcon,    layerprops:{ layerType: "sea",           exclusive:true  }, createFunction: createLayersGroupSea },  
    { label: "Weather",    icon: CloudIcon,  layerprops:{ layerType: "weather",       exclusive:true  }, createFunction: createLayersGroupWeather },      
    { label: "Bathy",      icon: BathyIcon,  layerprops:{ layerType: "bathymetry",    exclusive:false }, createFunction: createLayersGroupBathy },
    { label: "Wrecks",     icon: WrecksIcon, layerprops:{ layerType: "wrecks",        exclusive:false }, createFunction: createLayersGroupWrecks  },
    { label: "Bio",        icon: CloudIcon,  layerprops:{ layerType: "bio",           exclusive:true  }, createFunction: createLayersGroupBio }, 
    { label: "Misc",       icon: InfoIcon,   layerprops:{ layerType: "miscellaneous", exclusive:false }, createFunction: createLayersGroupMisc  },    
  ];


  export default function createAllLayersGroups() {    
    LAYERS_GROUPS[0].createFunction('test');
    const table = LAYERS_GROUPS.map( (layersGroup) => layersGroup.createFunction(layersGroup.layerprops.layerType) );    
    return( table.flat() );
      //LAYERS_GROUPS.map( (layersGroup) => layersGroup.createFunction(layersGroup.layerprops.layerType) )
    //);
  }