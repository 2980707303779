import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import {get as getProjection} from 'ol/proj.js';
import {getTopLeft, getWidth} from 'ol/extent.js';



// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupWeather(categoryId="weather") {
  
    const dt_now = new Date();
    const dateTime = dt_now.toISOString(); //'2024-05-09T00:00:00Z';
    console.log(dateTime);      
  
 
    // ------------------------------------------------------------------------------------------------------------
    //                                          Misc layers
    // ------------------------------------------------------------------------------------------------------------

    const layer_mf_cloud = new TileLayer({
      title: "Cloudiness (MF)",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://public-api.meteofrance.fr/public/arpege/1.0/wcs/MF-NWP-GLOBAL-ARPEGE-025-GLOBE-WCS/__OGC_REQUEST__?service=WCS&version=2.0.1",
        params: { LAYERS: "TOTAL_CLOUD_COVER__GROUND_OR_WATER_SURFACE___202401021200", TILED: true },  
        transition: 0,
      }),    
    });  


    const layerSatIRGlobalNOAA = new TileLayer({
      title: "Satellite (IR)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.5,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nowcoast.noaa.gov/geoserver/observations/satellite/ows", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "global_longwave_imagery_mosaic" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });   
    
    const layerSatVisiGlobalNOAA = new TileLayer({
      title: "Satellite (visible)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.5,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nowcoast.noaa.gov/geoserver/observations/satellite/ows", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "global_visible_imagery_mosaic" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });     


    const layerWindGFSIBL = new TileLayer({
      title: "Wind",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 1,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "wind", STYLES: "black-red-gusts" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });    

    const layerPrecipGFSIBL = new TileLayer({
      title: "Precipitations",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "precipitation-6h", STYLE: "" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });      
    
    const layerTempGFSIBL = new TileLayer({
      title: "Air Temperature",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "temperature" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });     
       

  return [    
    layerSatIRGlobalNOAA,      
    layerSatVisiGlobalNOAA,

    layerWindGFSIBL,
    layerPrecipGFSIBL,
    layerTempGFSIBL,
  ];
}
