import React, { useState } from 'react';
import Fab from "@mui/material/Fab";


function ButtonRound({id, label, icon, color="primary", bgColor="", size="medium", handleClick= f => f, top="", right="", bottom="", zIndex=1 }) { // top, right, selected,
  //console.log(bgColor);
  return (
    <Fab
      id={id}
      aria-label={label}
      size={size}
      onClick={() => handleClick()}
      color={color}
      sx={{ 
        zIndex: zIndex, 
        position: "absolute", 
        top: { top }, 
        bottom: { bottom },
        right: { right },
        background: { bgColor }, // "white", // "transparent"
        //border: selected ? "2px solid orange" : "2px solid transparent",
      }}            
    >
      {icon}
    </Fab>
  );
}

export default ButtonRound;