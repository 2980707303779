//import * as React from 'react';
import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
// import { Resizable } from 'react-resizable';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import CloseIcon from "@mui/icons-material/Close";


function PaperComponent(props) {
    const nodeRef = React.useRef(null); // https://stackoverflow.com/questions/63603902/finddomnode-is-deprecated-in-strictmode-finddomnode-was-passed-an-instance-of-d
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  

function PopupWindow({title, open, onClose, onRefresh, children}) {
    //const [open, setOpen] = React.useState(false); 

    /*
     return(
        <Dialog
          open={open}
          //maxWidth="sm"
          //fullWidth
          hideBackdrop={true}
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog> 
      ); */

    if (!open)
     return;
    else
     return (                  
      <Fade appear={false} in={open}>
        <Draggable handle="strong">
        <Paper
          //role="dialog"
          //aria-modal="false"
          //aria-label="Cookie banner"
          //square
          variant="elevation"
          //tabIndex={-1}
          //elevation={16}          
          //onMouseMove={handleResize}
          sx={{
            //zIndex:100,
            position: "fixed",
            left: "30%",
            top: "20%",
            // transform: "translate(100%, -50%)",            
            margin: "auto",            
            //m: 0,
            p: 2,
            paddingTop:0,
            //paddingBottom:10,
            //borderWidth: 2,
            border:2,
            maxWidth: "60%",
            //maxHeight: "50%",
            //width: "50%",
            //height:"50%"           
          }}

        >        
            <strong style={{ cursor: 'move' }}><div style={{ paddingTop:10, paddingBottom:10, paddingRight:30 }}>{title}</div></strong>
            <IconButton             
              onClick={onClose}
              style={{
                zIndex: 100,
                position: "absolute",
                top: 2,
                right: 4,
              }}
            >
               <CloseIcon />
            </IconButton>
            <Divider />
            <br />
            <div style={{  maxWidth: "300px",  maxHeight: "400px", overflow: 'auto' }}>                        
            {children}
            </div> 

            <Button autoFocus onClick={onRefresh}>
              Refresh
            </Button>
            
            <Button autoFocus onClick={onClose}>
              Close
            </Button>
 
        </Paper>
        </Draggable>
      </Fade>       
    );
}

export default PopupWindow;