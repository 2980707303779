import React, { useState } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ButtonBase from "@mui/material/ButtonBase";

import InfoIcon from "@mui/icons-material/Info";

function ButtonImage({ title, btn_width, btn_height, isSelected, exclusive, image, handleClick, handleClickInfo, popupLayerInfoVisible, popupLayerInfoSelected }) {
  const [isHovered, setIsHovered] = useState(false);
  const value = title;

  const myhandleClickInfo = (e) => {
    // if layer is not selected, do nothing (it will be selected with propagation)
    if (!isSelected) return;

    e.stopPropagation();
    handleClickInfo(value);
  };

  const handleMouseEnter = () => {
    if (isSelected) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (isSelected) setIsHovered(false);
  };  

  const iconInfoColor = (isHovered || popupLayerInfoSelected) ? "#000000" : "#D0D0D0";

  return (
    <ImageListItem key={value} component="div">
      <ButtonBase
        sx={{
          //border: is_selected ? "2px solid orange" : "2px solid transparent",
          borderRadius: "6px",
          backgroundColor:
            isSelected && exclusive ? "#e651003b" : "transparent",  // #e651003b
          padding: "0px",
        }}
        focusRipple
        key={value}
        onClick={() => handleClick(value)}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={image}
          alt={value}
          width={btn_width}
          height={btn_height}
          style={{
            border:
              isSelected && !exclusive
                ? "2px solid orange"
                : "2px solid transparent",
            backgroundColor:
              isSelected && !exclusive ? "#e651003b" : "transparent",
            borderRadius: "10px",
            padding: "4px",
          }}
        />
        {popupLayerInfoVisible && isSelected && handleClickInfo && (
          <InfoIcon
            fontSize="medium"            
            style={{
              zIndex: 100,
              position: "absolute",
              top: 20,
              right: 2,
              color: iconInfoColor
            }}
            onClick={myhandleClickInfo}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}            
          />
        )}
      </ButtonBase>

      <center>
        <ImageListItemBar
          subtitle={value} //subtitle={<span>by: {item.author}</span>}
          position="below"          
        />
      </center>
    </ImageListItem>
  );
}

export default ButtonImage;
