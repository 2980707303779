import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';
import OSM from "ol/source/OSM";


// return a table of WMS layer with url and layer anmes 
function createWMSLayers(url, tabLayersName) {
  return tabLayersName.map(layerName => new TileLayer({
    source: new TileWMS({
      url,
      params: { LAYERS: layerName, TILED: true },
      transition: 0,
    }),
  }));
}



// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupMisc(categoryId="misc") {

  const openSeaMapLayer = new TileLayer({
    title: "Sea map info",
    layerType: categoryId,
    zIndex: 100,  
    opacity: 1, //0.6,
    visible: false,
    layer_icon: "images/icon_layer_info_opensea_90x60.png",
    source: new OSM({
      opaque: false,
      url: "https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png",
    }),    
  });  
   

 
  const layer_group_shom_sedi = new LayerGroup({
    title: "Sediment",
    layerType: categoryId,
    visible: false,
    zIndex: 10,
    opacity: 0.6,    
    layer_icon: "images/icon_layer_info_sedim_90x60.png",
    layers: createWMSLayers("https://services.data.shom.fr/INSPIRE/wms/v", ["SEDIM_MONDIALE_BDD_4326_WMSV", "NATURES_FOND_500_BDD_WMSV"]),

    legend_description: 'The “World Seabed Sediment Map” product contains geo-referenced digital data, describing the nature of the sediment encountered in different seas and oceans of the world. The objects are all surface areas and the description of an object includes in particular the nature of the sediment including rock-type bottoms.',
    legend_url_graphic: 'https://services.data.shom.fr/INSPIRE/wms/v?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=NATURES_FOND_500_BDD_WMSV',
    legend_url_info: 'https://services.data.shom.fr/',
  });


  return [
    openSeaMapLayer,   
    layer_group_shom_sedi,         
  ];
}
