import React from "react";

import WorldIcon from "@mui/icons-material/Public";
//import WorldIcon from "@mui/icons-material/Moving";
import DiveSiteIcon from "@mui/icons-material/ScubaDiving";
import LayersIcon from "@mui/icons-material/Layers";

import PanelTabs from "./PanelTabs";
import TabPageOfArea from "./TabPageOfArea";
import TabPageOfDiveSites from "./TabPageOfDiveSites";
import TabPageLayers from "./TabPageOfLayers";
import PopupWindowLayerLegend from "./PopupLayerLegend";

import TileImage from 'ol/source/TileImage.js';

import { LAYERS_GROUPS } from "../layers/LayersGroupAll";


const MAIN_PAGES = [  
  { label: "Areas",      icon: WorldIcon,    tabPageType: TabPageOfArea },  
  { label: "Dive Sites", icon: DiveSiteIcon, tabPageType: TabPageOfDiveSites },    
];


function PanelTabsOfMapInfo({ getmap, defaultActivePage=0, ...props }) {
    const [popupLayerInfoVisible, setPopupLayerInfoVisible] = React.useState(''); // layer name, if empty hide popup
    const [layerInfoTitle, SetLayerInfoTitle] = React.useState();
    const [layerInfoContent, SetLayerInfoContent] = React.useState();
    const [layerInfoURLGraphic, SetLayerInfoURLGraphic] = React.useState();
    const [layerInfoURLDesc, SetLayerInfoURLDesc] = React.useState();

    const ALL_TABS = MAIN_PAGES.concat(LAYERS_GROUPS);

    const onClickInfoLayer = (layer_title) => {
        const mymap = getmap();
        if (!mymap) return;
    
        setPopupLayerInfoVisible(layer_title); //!popupLayerInfoVisible);
        //if (!popupLayerInfoVisible)
        //  return;
    
        const layer_info = mymap
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("title") === layer_title);
     
        if (layer_info.length === 0)
          return;    
    
        SetLayerInfoTitle(layer_title);
        console.log(layer_title);
        SetLayerInfoContent(layer_info[0].get("legend_description"));
        SetLayerInfoURLGraphic(layer_info[0].get("legend_url_graphic"));    
        SetLayerInfoURLDesc(layer_info[0].get("legend_url_info"));
    };        
        
    const onClosePopupInfoLayer = (event,reason) => {
        setPopupLayerInfoVisible('');
    }; 
      
    const onRefreshPopupInfoLayer = (event,reason) => {
        const mymap = getmap();
        if (!mymap) return;
        const layer_info = mymap
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("title") === layerInfoTitle);
     
        if (layer_info.length === 0)
          return;

        
        // TODO: check if source is of type TileImage
        const src = layer_info[0].getSource();  
                
        console.log(src.__proto__.constructor); // toString.call("string")

        layer_info[0].getSource().clear();
        layer_info[0].getSource().refresh();
    };       

    const renderTabPages = (activeTabPage) => {
        return (
        <>        
            {
            MAIN_PAGES.map((tabPage, i) => ( 
                <tabPage.tabPageType show={activeTabPage === i} getmap={getmap} />) 
            )}

            {LAYERS_GROUPS.map((tabPage, i) => (
                <TabPageLayers
                    id={tabPage.label}
                    key={tabPage.label}
                    value={activeTabPage}
                    index={i + MAIN_PAGES.length}
                    getmap={getmap}
                    layerType={tabPage.layerprops.layerType}
                    exclusive={tabPage.layerprops.exclusive}
                    onClickInfoLayer={onClickInfoLayer}
                    popupLayerInfoVisible={popupLayerInfoVisible}
                    onClosePopupInfoLayer={onClosePopupInfoLayer}
                    />
            ))}       
        
        </>
        );
    };    

    return (
        <>
            <PanelTabs                
                tabsInfo={ALL_TABS}
                defaultActivePage={defaultActivePage}
                tabPagesRenderingFunction={renderTabPages}                
                {...props}
            />

            <PopupWindowLayerLegend
                open={popupLayerInfoVisible !== ''}
                layerInfoTitle={layerInfoTitle}
                layerInfoContent={layerInfoContent}
                layerInfoURLDesc={layerInfoURLDesc}
                layerInfoURLGraphic={layerInfoURLGraphic}
                onRefresh={onRefreshPopupInfoLayer}
                onClose={onClosePopupInfoLayer}
            />
        </>         
    );
}

export default PanelTabsOfMapInfo;
