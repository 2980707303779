import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


import ButtonImage from "./ButtonImage";


const BUTTONS_SITETYPES = [
  { label: "ALL SITES",  value: "ALL", image: "images/icon_divesite_all.png" },
  { label: "EXPLO",      value: "EXPLO", image: "images/icon_divesite_explo.png" },
  { label: "WRECKS",     value: "WRECKS",  image: "images/icon_divesite_wreck2.png" },
  //{ label: "CAVE",       value: "CAVE", image: "images/icon_divesite_cave2.png" },
  //{ label: "PIT DIVING", value: "PIT DIVING", image: "images/icon_divesite_pitdiving.png" },
];

const BUTTONS_SITEDEPTH = [
  { label: "ALL DEPTH", depthUp: "",    depthDown: ""    },
  { label: "6m",        depthUp: "",    depthDown: "-6"  },
  { label: "12m",       depthUp: "-6",  depthDown: "-12" },
  { label: "20m",       depthUp: "-12",  depthDown: "-20" },
  { label: "40m",       depthUp: "-20", depthDown: "-40" },
  { label: "60m",       depthUp: "-40", depthDown: "-60" },
  { label: "100m", depthUp: "-60", depthDown: "-100" },
  { label: "200m", depthUp: "-100", depthDown: "-200" },
  { label: "Deeper", depthUp: "-200", depthDown: "-100000" },
];


function TabPageOfDiveSites({ getmap, show, ...props }) {

  const [selectedDiveSiteType, setSelectedDiveSiteType] = useState(BUTTONS_SITETYPES[0]);
  const [selectedDiveSiteDepth, setSelectedDiveSiteDepth] = useState(0);

  
  useEffect(() => {
    //console.log(selectedDiveSiteDepth);
    const sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];

    setSelectedDiveSiteType(sites_layer.get("ShowDiveSiteType"));

    const olDepth = sites_layer.get("ShowDiveSiteDepthDown");   
    BUTTONS_SITEDEPTH.map((tabDepth, i) => {
      if (olDepth === tabDepth.depthDown) {
        //console.log(i);
        setSelectedDiveSiteDepth(i);
      }
    });
    
  }, []); 

  const onClickSiteType = (sitetype) => {
    if (sitetype === selectedDiveSiteType)
      sitetype = ""; // hide layer

    setSelectedDiveSiteType(sitetype);

    let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
    sites_layer.set("ShowDiveSiteType", sitetype);
    sites_layer.getSource().changed();
  };

  const onClickSiteDepth = (id_sitedepth) => {
    console.log(selectedDiveSiteDepth);
    setSelectedDiveSiteDepth(id_sitedepth);
    let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
    sites_layer.set("ShowDiveSiteDepthUp", BUTTONS_SITEDEPTH[id_sitedepth].depthUp);
    sites_layer.set("ShowDiveSiteDepthDown", BUTTONS_SITEDEPTH[id_sitedepth].depthDown);
    sites_layer.getSource().changed();
  }; 

 
  return (
    <div hidden={!show}>
    <ImageList cols={1}>
      {BUTTONS_SITETYPES.map((tab_item, i) => (
        <ButtonImage
          key={i * 10}
          isSelected={tab_item.label === selectedDiveSiteType}
          exclusive={true}
          title={tab_item.label}
          image={tab_item.image}
          btn_width="90"
          btn_height="60"
          handleClick={onClickSiteType}
        />
      ))}
    </ImageList>
    
    <center>
      <ButtonGroup orientation="vertical" aria-label="Vertical button group" variant="text">
         {BUTTONS_SITEDEPTH.map((button, i) => {
            if (i === selectedDiveSiteDepth)
              return <Button key={i} onClick={() => onClickSiteDepth(i)} variant="contained" color="warning">{button.label}</Button>
            else
              return <Button key={i} onClick={() => onClickSiteDepth(i)}>{button.label}</Button>
          })}
      </ButtonGroup>
      </center> 
    </div>
  );
}

export default TabPageOfDiveSites;
