import React, { useEffect, useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';

import ShowListIcon from "@mui/icons-material/FormatListBulleted";
import AccountIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import WorldIcon from "@mui/icons-material/Public";
//import WorldIcon from "@mui/icons-material/TravelExplore";
import MyPositionIcon from "@mui/icons-material/GpsFixed"; // Adjust";
import LayersIcon from "@mui/icons-material/Layers";
import InfoIcon from "@mui/icons-material/Info";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import DiveIcon from "@mui/icons-material/ScubaDiving";
import SearchIcon from '@mui/icons-material/Search';
import DiveSiteIcon from "@mui/icons-material/ScubaDiving";
import DepthIcon from "@mui/icons-material/Waves";

import OpenLayerMap from "./components/OpenLayerMap";
import SiteSearch from "./components/SiteSearch";
import ButtonGroupMapSite from "./components/ButtonGroupMapSite";
import ButtonRound from "./components/ButtonRound";
import PanelTabsOfMapInfo from "./components/PanelTabsOfMapInfo";
import PanelBottomInfo from "./components/PanelBottomInfo";
import ButtonGeoLoc from "./components/ButtonGeoLoc";

const LAYERS_GROUP = [
  { label: "Maps", layerType: "background", icon: LayersIcon, exclusive:true  },
  { label: "Sea state",  layerType: "weather",  icon: CloudIcon,  exclusive:true },
  { label: "Info",       layerType: "info",       icon: InfoIcon,   exclusive:false },
];


const App = () => {
  const mapRef = useRef(null);
  const [txtMouseLatLon, SetTxtMouseLatLon] = useState('');
  const [txtMouseInfo, SetTxtMouseInfo] = useState('');

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,    
    backgroundColor: alpha(theme.palette.common.white, 0.50),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.85),
    },
    marginRight: theme.spacing(2),
    marginLeft: 10,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),

      [theme.breakpoints.up('sm')]: {
        width: '15ch',
        '&:hover': {
          width: '40ch',
        },
      },      
    },
  }));


  const getMap = () => {
    return mapRef.current;
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      
      <AppBar position="fixed" style={{ top:"0px", color:'black', boxShadow: 'none', backgroundColor: alpha('#000000', 0.07)}} > {/* background: 'transparent', */}
        <Toolbar >
          {/* Show site list Button */}
          <IconButton aria-label="show list" size="medium" style={{ backgroundColor: "#eeeeee" }} >
            <ShowListIcon />
          </IconButton>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          
          <Box sx={{ flexGrow: 1 }} />

          {/* Account Button */}                                                
          <IconButton aria-label="account" size="medium" style={{ backgroundColor:"#eeeeee" }} >
            <AccountIcon />
          </IconButton> 

        </Toolbar>
      </AppBar>

      {/* My Loc Button 
      <ButtonRound id="geolocButton" label="account" icon={<MyPositionIcon />} bottom="75px" right="34px" size="small" /> */}

      {/* OpenLayer Map */}
      <OpenLayerMap id="olmap" ref={mapRef} id_mouse_latlon="div_mouse_latlon" id_label_mouseover="mouse_info" />
     
      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="140px" // 100
        btnRight="15px"
        defaultActivePage={0}
        btnIcon={<WorldIcon />}
      />

      {/*
      <IconButton size="medium" style={{ position: "absolute", top:"85px", right:"20px" }} >
         <MyPositionIcon />
      </IconButton>  */}
      <ButtonGeoLoc getmap={getMap} /> 
       

      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="210px" // 170
        btnRight="15px"
        defaultActivePage={1}
        btnIcon={<DiveIcon />}
      />       

      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="280px" // 240
        btnRight="15px"
        defaultActivePage={2}
        btnIcon={<LayersIcon />}
      /> 
                      
      <PanelBottomInfo txtLatlonMouse={txtMouseLatLon} txtSiteInfoMouse={txtMouseInfo} />

    </div>
  );
};

export default App;
