import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupBio(categoryId="bio") {
  
 
    // ------------------------------------------------------------------------------------------------------------
    //                                          Misc layers
    // ------------------------------------------------------------------------------------------------------------

    const layerCoral = new TileLayer({
      title: "Coral",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://allencoralatlas.org/geoserver/ows",
        params: { LAYERS: "coral-atlas:benthic_data_verbose", VERSION: "2.0.0" },  
        transition: 0,
      }),    
    });  

       
  return [    
    layerCoral,
  ];
}
