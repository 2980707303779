import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';


// return a table of WMS layer with url and layer anmes 
function createWMSLayers(url, tabLayersName) {
  return tabLayersName.map(layerName => new TileLayer({
    source: new TileWMS({
      url,
      params: { LAYERS: layerName, TILED: true },
      transition: 0,
    }),
  }));
}


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupBathy(categoryId="bathy") {

  const opendem_layer = new TileLayer({
    title: "Depth contours 1",    
    layerType: categoryId,
    visible: false,
    zIndex: 100,
    opacity: 0.4,
    layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://www.opendem.info/geoserver/opendem/wms",
      params: { LAYERS: "gebco_2021_contours", TILED: true },
      transition: 0,
    }),
  });

  const layer_emodnet_contours = new TileLayer({
    title: "Depth contours",
    layerType: categoryId,
    visible: false,
    zIndex: 100,
    //opacity: 0.6,
    layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "emodnet:contours", TILED: true },
      transition: 0,
    }),    
  });


  const layer_group_shom_batti = new LayerGroup({
    title: "French coast",
    layerType: categoryId,
    visible: false,
    zIndex: 12,
    opacity: 0.6,
    layer_icon: "images/icon_layer_info_litto3Dfr_90x60.png",
    layers: createWMSLayers("https://services.data.shom.fr/INSPIRE/wms/r", [
      "L3D_MAR_LR_2011_PYR_3857_WMSR",
      "L3D_MAR_LR_2014_2015_WMSR_3857",
      "LITTO3D_PACA_2015_PYR_3857_WMSR",
      "L3D_LIDAR_CORSE_2017_2018_PYR_3857_WMSR",
      "L3D_MAR_AQT_2020_2022_PYR_3857_WMSR",
      "L3D_MAR_BZH_2018_2021_WMSR_3857",
      "L3D_MAR_MORBIHAN_2015_PYR_3857_WMSR",
      "L3D_MAR_NHDF_2016_2018_PYR_3857_WMSR",
      "L3D_MAR_PNMI_2012_PYR_3857_WMSR"
    ]),

    legend_description: 'Litto3D Marine Part is an intermediate product of the Litto3D product containing only marine data.Litto3D is a unique and continuous land-sea database which can provide 3D models of the shape and location of French coastal terrain. A line separates sea and land data acquisition zones, and the associated survey readings obtained using bathymetric lidar technologies or multibeam echosounders for sea readings and airborne lidar topographical surveys for land readings, with 300 m overlap. Litto3D digital models are available in two formats: a set of 3D points and a qualified terrain mesh.',
    legend_url_graphic: 'https://services.data.shom.fr/static/legends/FR/MNT_LITTORAL_LITTO3D/legende_litto3d_LR_PACA.png',
    legend_url_info: 'https://services.data.shom.fr/geonetwork/INSPIRE?service=CSW&version=2.0.2&request=GetRecordById&Id=BATHYMETRIE_L3D_MAR_LR_2011.xml',
  });    


  return [   
    //opendem_layer,
    layer_emodnet_contours,
    layer_group_shom_batti,           
  ];
}
